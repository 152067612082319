/* eslint-disable @typescript-eslint/no-var-requires */
const React = require("react");
const { SnackbarProvider } = require("notistack");

exports.wrapRootElement = ({ element }) => {
  return (
    <SnackbarProvider
      maxSnack={3}
      classes={{
        variantSuccess: "snack-success",
        variantError: "snack-error",
        variantWarning: "snack-warning",
        variantInfo: "snack-info",
      }}
    >
      {element}
    </SnackbarProvider>
  );
};
