// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-data-source-page-tsx": () => import("./../../../src/components/dataSourcePage.tsx" /* webpackChunkName: "component---src-components-data-source-page-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/about-us.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-cart-tsx": () => import("./../../../src/pages/cart.tsx" /* webpackChunkName: "component---src-pages-cart-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-data-sources-tsx": () => import("./../../../src/pages/data-sources.tsx" /* webpackChunkName: "component---src-pages-data-sources-tsx" */),
  "component---src-pages-imprint-tsx": () => import("./../../../src/pages/imprint.tsx" /* webpackChunkName: "component---src-pages-imprint-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-press-tsx": () => import("./../../../src/pages/press.tsx" /* webpackChunkName: "component---src-pages-press-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-products-coach-boat-pro-tsx": () => import("./../../../src/pages/products/coach-boat-pro.tsx" /* webpackChunkName: "component---src-pages-products-coach-boat-pro-tsx" */),
  "component---src-pages-products-data-bay-tsx": () => import("./../../../src/pages/products/data-bay.tsx" /* webpackChunkName: "component---src-pages-products-data-bay-tsx" */),
  "component---src-pages-products-dinghy-edition-tsx": () => import("./../../../src/pages/products/dinghy-edition.tsx" /* webpackChunkName: "component---src-pages-products-dinghy-edition-tsx" */),
  "component---src-pages-products-tsx": () => import("./../../../src/pages/products.tsx" /* webpackChunkName: "component---src-pages-products-tsx" */),
  "component---src-pages-products-yacht-edition-tsx": () => import("./../../../src/pages/products/yacht-edition.tsx" /* webpackChunkName: "component---src-pages-products-yacht-edition-tsx" */),
  "component---src-pages-services-tsx": () => import("./../../../src/pages/services.tsx" /* webpackChunkName: "component---src-pages-services-tsx" */),
  "component---src-pages-solutions-leisure-tsx": () => import("./../../../src/pages/solutions/leisure.tsx" /* webpackChunkName: "component---src-pages-solutions-leisure-tsx" */),
  "component---src-pages-solutions-racing-tsx": () => import("./../../../src/pages/solutions/racing.tsx" /* webpackChunkName: "component---src-pages-solutions-racing-tsx" */),
  "component---src-pages-solutions-tsx": () => import("./../../../src/pages/solutions.tsx" /* webpackChunkName: "component---src-pages-solutions-tsx" */),
  "component---src-pages-success-tsx": () => import("./../../../src/pages/success.tsx" /* webpackChunkName: "component---src-pages-success-tsx" */),
  "component---src-pages-support-manuals-tsx": () => import("./../../../src/pages/support/manuals.tsx" /* webpackChunkName: "component---src-pages-support-manuals-tsx" */),
  "component---src-pages-support-submit-request-tsx": () => import("./../../../src/pages/support/submit-request.tsx" /* webpackChunkName: "component---src-pages-support-submit-request-tsx" */),
  "component---src-pages-support-tsx": () => import("./../../../src/pages/support.tsx" /* webpackChunkName: "component---src-pages-support-tsx" */),
  "component---src-pages-support-tutorials-tsx": () => import("./../../../src/pages/support/tutorials.tsx" /* webpackChunkName: "component---src-pages-support-tutorials-tsx" */),
  "component---src-pages-terms-and-conditions-tsx": () => import("./../../../src/pages/terms-and-conditions.tsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-tsx" */)
}

